import React from "react";
import Texts from "../../../utils/lang-utils";
import "./HowItWorks.scss";
import urls from "../../../areas/main/assets/urls";
const HowItWorks = () => {
  return (
    <section className="service-one how-it-works" id="integrations">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            {Texts.QROrdering.HowItWorksTitle}
          </h2>
          <p>{Texts.QROrdering.HowItWorksSubtitle}</p>
        </div>
        <div className="row">
          <div
            className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="text-center">
              <div className="service-one__inner">
                <i className="fa fa-qrcode service-one__icon "></i>
                <h4>{Texts.QROrdering.HowItWorksItem1Title}</h4>
                <p>{Texts.QROrdering.HowItWorksItem1Subtitle}</p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="text-center">
              <div className="service-one__inner ">
                <i className="fa fa-comments service-one__icon"></i>
                <h4>{Texts.QROrdering.HowItWorksItem2Title}</h4>
                <p>{Texts.QROrdering.HowItWorksItem2Subtitle}</p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="text-center">
              <div className="service-one__inner">
                <i className="fa fa-mobile service-one__icon"></i>
                <h4>{Texts.QROrdering.HowItWorksItem3Title}</h4>
                <p>{Texts.QROrdering.HowItWorksItem3Subtitle}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
                    <div className="text-center mt-5">
                        <a href={urls.onboarding} className="thm-btn">
                            <span>{Texts.GetStarted}</span>
                        </a>
                    </div>

                </div>
            </div>

    </section>
  );
};
export default HowItWorks;
